const prod_config = {
    API_URL: 'https://api.codegirls.consulnet.net/',
    SITE_KEY: '6LdVtoMUAAAAANm-nVlHFBtXJ4hyPqSMv9dhJ6ck',
    UPLOAD_DIR: '/images/uploads',
};

const dev_config = {
    API_URL: 'http://192.168.14.62:3334/',
    SITE_KEY: '6LdWx8EZAAAAAJ-dqKCX6NSyvRNhhgX7P_1COF0c',
    UPLOAD_DIR: '/assets/uploads/',
};

const staging_config = {
    API_URL: 'https://api.staging.consulnet.net/',
    SITE_KEY: '6LdSt8MEAAAAAA-StKmFb6QsJfhyGpT7RqfgStGK',
    UPLOAD_DIR: '/images/uploads',
};

export const config = (() => {
    switch (process.env.NODE_ENV) {
        case 'development':
            return dev_config;
        case 'staging':
            return staging_config;
        default:
            return prod_config;
    }
})();
