import React, { Component } from "react";
import { Link } from "react-router-dom";

class PageBanner extends Component {
  state = {};
  render() {
    return (
      <section className="banner">
        <div className="container">
          <div className="page-title-wrapper">
            <div className="page-title-captions">
              <h3 className="entry-title" id="head">
                {this.props.heading} <span>{this.props.Gheading}</span>
              </h3>
            </div>
            <ul className="breadcrumbs">
              <li>
                {" "}
                <Link to="/">Home</Link>
              </li>
              {this.props.more ? (
                <li>
                  <Link to={this.props.link}>{this.props.more}</Link>
                </li>
              ) : null}
              <li>{this.props.page}</li>
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

export default PageBanner;
