import React, { useState } from "react";
import { config } from '../../config';
import {Link} from 'react-router-dom';


const StudentListing = ({CGVoice}) => {
  const [visibleStudents, setVisibleStudents] = useState(9);

  // Handle Load More button click
  const handleLoadMore = () => {
    setVisibleStudents(visibleStudents + 3);
  };

  

  return (
    <section className="container common_section codegirls_voices_listing">
      <h2>CodeGirls <span className="green">Voices</span></h2>
      <h3>Through Vocational IT & Business Training</h3>
      <div className="student-list">
        {
        CGVoice && CGVoice.slice(0, visibleStudents).map((student) => (
          <StudentCard
            key={student.id}
            slug={student?.Voice_slug} 
            image={student.Voice_Image}
            name={student?.Voice_title}
            designation={student.Voice_job_title}
          />
        ))}
      </div>
      {visibleStudents < CGVoice.length && (
        <button className="load-more-button" onClick={handleLoadMore}>
          Load More
        </button>
      )}
    </section>
  );
};

// Subcomponent for individual student cards
const StudentCard = ({ image, name, designation, slug }) => {
  return (
    <div className="student-card">
      <img src={image ? `${config.UPLOAD_DIR}${image}` : "images/girl-placeholder.png"} alt={name || "Placeholder"} className="student-image" onError={(e) => (e.target.src = "images/girl-placeholder.png")} />
      <div className="student_listing_detail">
        <h4 className="student-name">{name}</h4>
        <p className="student-designation">{designation}</p>
        <Link className="read-story-button" to={`/voice-of-codegirls-detail/${slug}`}>Discover her Voice</Link>
        </div>
    </div>
  );
};

export default StudentListing;
