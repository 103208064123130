import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Loadable from 'react-loadable';
import App from "./Home";
// import About from "./pages/About";
// import MediaGallery from "./pages/MediaGallery";
// import SingleAlbum from "./pages/SingleAlbum";
// import InThePress from "./pages/InThePress";
// import Hire from "./pages/HireADeveloper";
// import SingleEvent from "./pages/SingleEvent";
// import Form from "./pages/Form";
// import Apply from "./pages/BecomeCodeGirl";
// import Blogs from "./pages/Blogs";
// import BlogSingle from "./pages/BlogSingle";
// import PrivacyPolicy from "./pages/PrivacyPolicy";
// import Terms from "./pages/Terms&Conditions";
// import ThankYou from "./pages/ThankYou";
import PageNotFoundError from "./pages/PageNotFound";
import Voiceofcodegirls from "./pages/VoiceOfCodegirls";
function Loading({ pastDelay, error }) {
  if (error) {
    return 'Oh no!';
  } else {
    return pastDelay ? <h3></h3> : null;
  }
}

const About = Loadable({
  loader: () => import('./pages/About'),
  loading: Loading,
  delay: 30
});
const MediaGallery = Loadable({
  loader: () => import('./pages/MediaGallery'),
  loading: Loading,
  delay: 30
});
const SingleAlbum = Loadable({
  loader: () => import('./pages/SingleAlbum'),
  loading: Loading,
  delay: 30
});
const InThePress = Loadable({
  loader: () => import('./pages/InThePress'),
  loading: Loading,
  delay: 30
});
const Hire = Loadable({
  loader: () => import('./pages/HireADeveloper'),
  loading: Loading,
  delay: 30
});
const SingleEvent = Loadable({
  loader: () => import('./pages/SingleEvent'),
  loading: Loading,
  delay: 30
});
const Form = Loadable({
  loader: () => import('./pages/Form'),
  loading: Loading,
  delay: 30
});
const Apply = Loadable({
  loader: () => import('./pages/BecomeCodeGirl'),
  loading: Loading,
  delay: 30
});
const SA = Loadable({
  loader: () => import('./pages/BecomeCodegirlsSA'),
  loading: Loading,
  delay: 30
});
const Blogs = Loadable({
  loader: () => import('./pages/Blogs'),
  loading: Loading,
  delay: 30
});
const AwardWinning = Loadable({
  loader: () => import('./pages/AwardWinning'),
  loading: Loading,
  delay: 30
});
const BlogSingle = Loadable({
  loader: () => import('./pages/BlogSingle'),
  loading: Loading,
  delay: 30
});
const PrivacyPolicy = Loadable({
  loader: () => import('./pages/PrivacyPolicy'),
  loading: Loading,
  delay: 30
});
const Terms = Loadable({
  loader: () => import('./pages/Terms&Conditions'),
  loading: Loading,
  delay: 30
});
const ThankYou = Loadable({
  loader: () => import('./pages/ThankYou'),
  loading: Loading,
  delay: 30
});
const Sponsorship = Loadable({
  loader: () => import('./pages/Sponsorship'),
  loading: Loading,
  delay: 30
});

const Voice = Loadable({
  loader: () => import('./pages/VoiceOfCodegirls'),
  loading: Loading,
  delay: 30
});
const VoiceSingle = Loadable({
  loader: () => import('./pages/VoiceOfCodegirlsDetail'),
  loading: Loading,
  delay: 30
});
const Report = Loadable({
  loader: () => import('./pages/Report'),
  loading: Loading,
  delay: 30
});
const routing = (
  <Router>
    <div>
      <Switch>
      <Route exact path="/" component={App} />
      <Route exact path="/sponsorship" component={Sponsorship} />
      <Route exact path="/thank-you" component={ThankYou} />
      <Route exact path="/about-us" component={About} />
      <Route exact path="/media-gallery" component={MediaGallery} />
      <Route exact path="/in-the-press" component={InThePress} />
      <Route exact path="/hire-a-codegirl" component={Hire} />
      <Route exact path="/fourth-graduation-ceremony" component={SingleEvent} />
      <Route exact path="/pasha-launches-prowomen" component={SingleEvent} />
      <Route exact path="/codegirls-turns-3" component={SingleEvent} />
      <Route exact path="/bridging-the-digital" component={SingleEvent} />
      <Route exact path="/voice-of-codegirls" component={Voiceofcodegirls} />
      <Route exact path="/voice-of-codegirls-detail/:slug" component={VoiceSingle} />
      <Route exact path="/report" component={Report} />
      <Route exact path="/codegirls-5-years-convention" component={SingleEvent} />
      <Route exact path="/codegirls-skardu-cohort-3-graduates-50-women-in-tech" component={SingleEvent} />
      <Route
        exact
        path="/codegirlskarachi-celebrated-its-1st-anniversary-along-with-its-3rd-graduation-ceremony"
        component={SingleEvent}
      />
      <Route exact path="/google-io-recap" component={SingleEvent} />
      <Route exact path="/first-graduation-ceremony" component={SingleEvent} />
      <Route exact path="/become-a-codegirl" component={Apply} />
      <Route exact path="/become-a-codegirl-south-africa" component={SA} />
      <Route exact path="/apply-for-registration" component={Apply} />
      <Route exact path="/blog" component={Blogs} />
      <Route exact path="/award-winning" component={AwardWinning} />
      <Route exact path="/blog-details/*" component={BlogSingle} />
      <Route exact path="/become-a-trainer" component={Form} />
      <Route exact path="/gallery:albumName" component={SingleAlbum} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/terms-and-conditions" component={Terms} />
      <Route component={PageNotFoundError} />

      </Switch>
    </div>
  </Router>
);

export default routing;
