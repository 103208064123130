import React from "react";
import "./internal.css";
import Header from "../components/Common/header";
import LastSection from "../components/Common/lastSection";
import Footer from "../components/Common/footer";
import PageBanner from "../components/Common/pageBanner";
import Featured from "../components/Voice/featured"; 
import StudentListing from "../components/Voice/studentStories"; 
import { useEffect } from "react";
import { useState } from "react";
import Axios from "axios";
import { config } from "../config";


function Voiceofcodegirls({ location }) {

  const[CGVoice,setCGVoice]=useState([]);
  const[loading,setLoading]=useState(false);

  const [recent] = CGVoice;


  const getVoiceData = async () => {
    setLoading(true)
      try {
        const res = await Axios.get(`${config.API_URL}get-all-voice`)

        if (res.status === 200) {
          const{ data } = res;
          setLoading(false)
          setCGVoice(data ?? []);
        }
          
        } catch (error) {
        console.log(error);
        setLoading(false);
        
      }
  }

  useEffect(()=>{
    getVoiceData();
  },[])


    return (
      <React.Fragment>
        <Header />
        <main>
          <Featured featuredPost={recent}/>
          <StudentListing CGVoice={CGVoice}/>
          <LastSection />
        </main>
        <Footer />
      </React.Fragment>
    );
  }

  export default Voiceofcodegirls;
