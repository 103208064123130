import React from "react";
import { Link } from "react-router-dom"; // Use Link for navigation
import { config } from "../../config";

const Featured = ({featuredPost}) => {  

    if (!featuredPost) {
      return "";
    }
    const heading = featuredPost?.Voice_title || ""; // Get the heading dynamically
    const words = heading.split(" "); // Split the heading into words
    const lastWord = words.pop(); // Extract the last word
    const restOfTheText = words.join(" "); // Combine the remaining words
    
    return (
      <section className="feature_section common_section">
        <div className="container">
          <div className="flex-row">
            {/* Left Column */}
            <div className="column-7">
              <h3>Voices of CodeGirls</h3>
              <h1>
              {restOfTheText}{" "}
              <span style={{ color: "#179E5A" }}>{lastWord}</span>
            </h1>
              <p>{featuredPost?.Voice_short_content}</p>
              <Link to={`/voice-of-codegirls-detail/${featuredPost?.Voice_slug}`} className="btn">
              Discover her Voice
              </Link>
            </div>

            {/* Right Column */}
            <div className="column-5">
            <img
              src={featuredPost?.Voice_Image ? `${config.UPLOAD_DIR}${featuredPost.Voice_Image}` : "images/girl-placeholder.png"}
              alt={featuredPost?.Voice_title || "Placeholder"}
              className="featured-image"
              onError={(e) => (e.target.src = "images/girl-placeholder.png")}
            />
            </div>
          </div>
        </div>
      </section>
    );
  }


export default Featured;
